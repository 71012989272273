import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';

import { IntlNetwork } from '../helpers/language';

import ArticlePage from '../pages/ArticlePage';
import { withTranslation } from 'react-i18next';

class RulesContainer extends Component {
  formatRules = (str) => {
    const rules = str.split(';');
    return (
      <React.Fragment>
        {rules.map((rule, index) => (
          <Rule key={`rule_${index}`} rule={rule} />
        ))}
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <ArticlePage
        content={this.formatRules(IntlNetwork(t, 'highscore-rules-page-rules'))}
        header={t('rulescontainer-header')}
      />
    );
  }
}

const Rule = ({ rule }) => (
  <div className={css(styles.rule, window.innerWidth < 700 && styles.ruleSmall)}>
    {/* bold header */}
    <strong>{rule.substr(0, rule.indexOf('– '))}</strong>
    <div
      className={css(
        styles.ruleDescription,
        window.innerWidth < 700 && styles.ruleDescriptionSmall,
      )}
    >
      {/* capitalize first letter and replace \n strings */}
      {[rule.substr(rule.indexOf('– ') + 1, 2).toUpperCase(), rule.substr(rule.indexOf('– ') + 3)]
        .join('')
        .split(/\\n/)
        .map((paragraph, index) => (
          <p key={`paragraph_${index}`}>{paragraph.replace(/\\/, '')}</p>
        ))}
    </div>
  </div>
);

const styles = StyleSheet.create({
  rule: {
    fontSize: 18,
    marginBottom: 30,
  },
  ruleSmall: {
    fontSize: 17,
  },
});

export default withTranslation()(RulesContainer);
